<template>
  <div class="my-second info bgcolor">
      <Head :title="$t('info.vip_info')" :show="true" ></Head>
      <div class="common-box info-box">
          <div class="info-main" v-if="user">
                <van-cell-group  class="avatar-box">
                    <van-cell size="large"  :title="$t('info.user_img')"  @click="changeAvatar" clickable   >
                         <template #default>
                             <input  type="file" name="file" id="download" ref="download" style="display:none" @change="uploadFile()" />
                             <img class="avatar" :src="user.headimgurl"  alt="">
                        </template>
                    </van-cell>
                </van-cell-group>
                <van-cell-group size="large" class="userinfo-box">
                    <van-cell :to="{name:'mine-nickname',query:{nickname:user.username}}" size="large" :value="user.username"  :title="$t('info.user_name')"  is-link   />
                    <van-cell :to="{name:'mine-phone-reset'}"  size="large" :value="'+'+user.area+' '+user.phone_number"  :title="$t('login.phone')"  is-link   />
                    <van-cell :to="{name:'mine-email',query:{email:user.email}}" size="large" :value="user.email?user.email: $t('info.not_bind')"  :title="$t('info.emails')"  is-link   />
                    <van-cell size="large" :value="user.uid"  :title="$t('info.id_nums')"   />
                    <van-cell size="large" v-if="user"  :value="$t('info.sex_'+user.sex)"  :title="$t('info.sexs')"  is-link  @click="showPicker=true"  />
                </van-cell-group>

                <van-cell-group  class="password-box">
                    <van-cell size="large"  :to="{name:'mine-pass'}"  :title="$t('info.change_login_psd')"  is-link   />
                    <van-cell size="large"  :to="{name:'mine-secondpass'}"  :title="$t('info.change_two_psd')"  is-link   />
                    <van-cell size="large"  :to="{name:'mine-resetpindone'}"  :title="$t('info.get_two_psd')"  is-link   />
                </van-cell-group>

                <van-cell-group  class="password-box">
                    <van-cell size="large" :class="lang=='cn'?'':'small-text'" :to="{name:'mine-realname'}" :value="$t('mine.kyc'+user.is_kyc)"  :title="$t('info.user_real')"  is-link   />
                    <van-cell size="large"  :to="{name:'mine-card'}" :value="user.isbank==1?$t('info.has_bind'):$t('info.not_bind')"  :title="$t('info.card_num')"  is-link   />
                    <van-cell size="large"  :title="$t('info.address')"  is-link  :to="{name:'mine-address'}"  />
                </van-cell-group>
          </div>

          <van-popup class="pick-popup" :style="{ height: '6.6rem' }" v-model="showPicker" position="bottom" duration="0.3">
            <van-picker
                :title="$t('info.choose_sexs')"
                show-toolbar
                v-if="user"
                :confirm-button-text="$t('info.complate')"
                :columns="columns"
                @confirm="onConfirm"
                @cancel="showPicker = false"
                value-key="value"
                :default-index="user.sex-1"
            />
            </van-popup>
      </div>
  </div>
</template>


<script>
import {myinfo} from "@/common"
import {Notify} from "vant"
export default {
    mixins:[myinfo],
    data(){
        return {
            showPicker:false,
            columns:[{value: this.$t('info.nam'),id:1},{value: this.$t('info.woman'),id:2},{value: this.$t('info.keep_sevret'),id:3}],
            sex: this.$t('info.woman'),
            lang:localStorage.getItem("langData") || 'th',
        }
    },
    methods:{
        changeAvatar(){
            this.$refs.download.click();
        },
        uploadFile(){
            // 修改头像 
            this.file = this.$refs.download.files[0];
            let formData = new FormData();
            formData.append('head',this.file);
            this.$store.commit("setnoMsg",true);
            this.$ajax.uploaduserhead(formData)
            .then(res=>{
                if(res.code==200){
                    var file_path = res.data.file_path;
                      // $host
                    this.$ajax.updateuser({ face_image:file_path})
                    .then(res=>{
                        if(res.code==200){
                            this.setdashboard({...this.user,headimgurl:this.$host+file_path});
                            
                        }
                    })
                }
            })
            
        },
        onConfirm(value){
            // 修改性别
            this.$ajax.updateuser({ sex:value.id})
            .then(res=>{
                if(res.code==200){
                    this.showPicker = false;
                    this.setdashboard({...this.user,sex:value.id});
                }
                
            })
        },
    }
}
</script>

